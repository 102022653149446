<template>
  <svg
    v-once
    width="16"
    height="16"
    viewBox="0 0 250 250"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <g fill="#8a05be">
      <path
        d="M117 245 c-7.7 -0.8 -22.8 -3.6 -26 -4.8 -2 -0.8 -1.5 -1.2 4.6 -3.8 15.3 -6.4 37 -18.4 49.8 -27.4 25.6 -18.1 54 -46.9 70.2 -71.3 9.6 -14.5 22.7 -38.7 25.4 -47 0.7 -2.2 1.6 -3.5 2 -3 0.4 0.4 1.6 4.4 2.7 8.8 2.6 11.5 2.6 42.6 -0.1 53.9 -1 4.4 -2.2 8.7 -2.7 9.5 -0.5 0.9 -2.2 5.4 -3.9 10.1 -1.7 4.7 -3.8 9.6 -4.9 11 -1 1.4 -2.6 4.1 -3.6 6 -5.5 10.7 -21.1 27.1 -35.5 37.2 -20.8 14.6 -53.7 23.4 -78 20.8z"
      />
      <path
        d="M53.4 221.1 c-3.8 -2.7 -10.5 -8.6 -14.9 -13.1 l-7.9 -8.2 6.4 -2.4 c15.8 -5.8 33.4 -15 54.7 -28.3 l6.2 -3.9 15.2 14.9 15.2 14.9 -2.7 2.1 c-2.4 1.9 -11.5 7.2 -20.6 12 -12.3 6.6 -38.4 16.9 -42.7 16.9 -1.1 0 -5.1 -2.2 -8.9 -4.9z"
      />
      <path
        d="M15.6 174.3 c-3.1 -6.1 -7.2 -19.4 -10.3 -32.9 -0.4 -1.8 0.7 -2.5 9.4 -5.8 28.9 -11.1 53.7 -27 75.1 -48.3 6.3 -6.2 11.7 -11.3 12.1 -11.3 1.1 0 30.1 28.9 30.1 30 0 3.4 -31.4 31.7 -47 42.3 -17 11.6 -38.8 23.3 -52.5 28.2 -2.7 1 -5.4 2.2 -6 2.5 -0.5 0.4 -2.4 1.2 -4.1 1.7 l-3.1 1.1 -3.7 -7.5z"
      />
    </g>
    <g fill="#07a8fb">
      <path
        d="M134.9 172.7 c-5.8 -5.6 -12.7 -12.6 -15.3 -15.5 l-4.9 -5.2 4.9 -4.2 c23 -19.8 45.4 -46 59.6 -69.8 5.7 -9.5 15.1 -27.4 16.8 -32 2.9 -7.8 7.4 -17 8.2 -17 2.5 0 24.3 25.3 25.3 29.3 0.6 2.3 -2.1 10.8 -7.5 23.7 -3.5 8.5 -13.6 28.8 -15.4 31 -0.7 0.8 -2.5 3.8 -4.1 6.5 -3.9 7 -12.4 18.3 -22.1 29.6 -8.7 10 -26.8 27.9 -32 31.6 l-3 2.2 -10.5 -10.2z"
      />
      <path
        d="M4.5 111.3 c0.4 -3.2 2.2 -11.7 4 -18.8 7.6 -30.4 27 -56.6 54.6 -73.7 13.9 -8.7 38.4 -16.8 50.7 -16.8 2.6 0 5.3 -0.5 6 -1.2 0.7 -0.7 1.2 -1 1.2 -0.7 0 2 -4.7 13.6 -9 21.9 -15.3 30.2 -37 54.8 -64.5 73.4 -10.1 6.8 -38.9 21.6 -41.9 21.6 -1.5 0 -1.7 -0.7 -1.1 -5.7z"
      />
      <path
        d="M134.9 78.7 c-5.8 -5.6 -12.7 -12.5 -15.3 -15.4 l-4.8 -5.2 2 -2.8 c7.3 -10.3 19.8 -34.7 23.7 -46.1 2.2 -6.3 2.5 -6.7 5.1 -6.4 4.2 0.4 16.7 3.3 17.9 4.2 0.6 0.5 3 1.3 5.4 2 6.6 1.7 15.1 5.7 15.7 7.3 1.2 3.3 -15.7 39.5 -26.1 55.7 -5.5 8.6 -11.7 17 -12.5 17 -0.3 0 -5.3 -4.6 -11.1 -10.3z"
      />
    </g>
  </svg>
</template>
